@import '~font-awesome/css/font-awesome.min.css';

@import 'variables/breakpoints';

@import 'animations';

@import 'layout';

@import 'margins';

@import 'components';

@import 'variables/variables';

@import 'variables/fonts';

@import 'inputs';

* {
  box-sizing: border-box;
  font-family: $rubik;
  
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label,
input,
a,
button,
select,
textarea {
  color: inherit;
  display: block;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

input,
select,
textarea,
button {
  border: 0;
  border-radius: 0;
}

button {
  background: transparent;
  cursor: pointer;
}

a {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

.anchor {
  position: relative;
}
