$transparent: transparent;
$white: #FFF;
$black: #000;
$black-tooltip: #222;
$azure: #35599F;
$valencia: #D34836;
$yellow: #F0D548;
$light-yellow: #f5e599;
$darker-yellow:#FFC700;
$tangerine-yellow: #FFC701;
$nero: #1D1D1D;
$gray-default: #808080;
$gray: #CBCBCB;
$dark-white: #F5F1F1;

$pigment-indigo: #490076; // violeta principal
$biloba-flower: #BB9AEF; // lila secundario
$perfume: #CAAFF2; // lila principal
$saffron :#F7BF36; // oro
$peach: #FFE6AE; // crema secundario
$serenade: #FFEFE2 // crema principal